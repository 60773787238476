import styleTw from './footer-tw.i.css';
const sheetTw = new CSSStyleSheet();
sheetTw.replaceSync(styleTw);

class CourseFooter extends HTMLElement {
  static get observedAttributes() {
    return ['courses', 'current'];
  }
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.adoptedStyleSheets = [sheetTw];
    this.render();
  }

  render() {
    this.shadowRoot.innerHTML = `
    <div>
      <footer class="lg:grid grid-cols-4 mt-3">
        <div>
          <dl class="info"></dl>
          <a itemprop="url" href="https://www.linkedin.com/in/robertgurgul" target="_blank" class="grayscale">
              <img src="https://debugger.pl/assets/me/in2.png" class="w-6 mt-4" alt="View Robert Gurgul's profile" />
          </a>
        </div>
      </footer>
      <div class=" mt-10 marker-container">
        <div>
            Copyright &copy; 2011-${new Date().getFullYear().toString()}, DEBUGGER
            <br>
            Wszelkie prawa zastrzeżone
        </div>
        <div>
            Strona używa plików cookies w celach statystycznych
        </div>
      </div>
    </div>
         `;
  }

  renderInfo() {
    const infoEl = this.shadowRoot.querySelector('.info')
    const infoPages = ['kontakt', 'o-mnie', 'referencje', 'wycena'];
    infoEl.innerHTML = `
      <dt class="mb-5">
        <a href="/">
          <img class="logo bg-[#F5F6F7]" width="110" src="https://debugger.pl/assets/vectors/logo.svg" alt="logo" />
        </a>
      </dt>
      ${infoPages
        .map(
          (val) => `<dd><a class="capitalize marker-container block py-0.5
          ${this.current === val ? 'text-opacity-30 cursor-default' : 'text-opacity-100'}"
                    href="https://debugger.pl/${val}">
                              <div>${val.replace(/-/, () => ' ')}</div>
                          </a></dd>`
        )
        .join('')}`
  }

  renderCourses(promise) {
    const footer = this.shadowRoot.querySelector('footer');
    const front = document.createElement('div');
    const back = document.createElement('div');
    const inne = document.createElement('div');
    footer.appendChild(front);
    footer.appendChild(back);
    footer.appendChild(inne);

    promise.then((resp) => {
      const data = resp.reduce(
        (acc, val) => ({ ...acc, [val.type]: [...acc[val.type], val] }),
        { 'front-end': [], 'back-end': [], inne: [] }
      );
      const dataArray = Object.entries(data);

      dataArray.forEach((col) => {
        const tpl = `
                    <dl>
                        <dt
                          class="uppercase py-5 lg:py-1.5 font-semibold marker-container">
                          <div>szkolenia ${col[0]}</div>
                        </dt>
                    ${col[1]
            .filter(({ hidden }) => !hidden)
            .map(
              (item) => `<dd>
                            <a href='https://debugger.pl/szkolenie-${item.name}'
                            class="capitalize marker-container block py-0.5
                            ${this.current === item.name ? 'text-opacity-30 cursor-default pointer-events-none' : 'text-opacity-100'}"
                            >
                 <div>
                            ${item.name.charAt(0).toUpperCase() +
                item.name
                  .slice(1)
                  .replace(/-[\w]/g, (val, idx, str) => {
                    const result =
                      str.charAt(idx + 2) !== '-'
                        ? val.charAt(1).toUpperCase()
                        : val.charAt(1);
                    return ' ' + result;
                  })
                }
                </div>
                            </a>
                        </dd>`
            )
            .join('')}
                       </dl> `;

        switch (true) {
          case col[0].includes('front'):
            front.innerHTML = tpl;
            break;
          case col[0].includes('back'):
            back.innerHTML = tpl;
            break;
          case col[0].includes('inne'):
            inne.innerHTML = tpl;
            break;
          default:
            break;
        }
      });
    });
  }
  connectedCallback() {
    const hasOnlyStaticChildren = this.shadowRoot.querySelector('footer').children.length === 1;
    hasOnlyStaticChildren && this.attributeChangedCallback('courses');
    !this.current && this.renderInfo();
  }
  attributeChangedCallback(name, old, value) {
    switch (name) {
      case 'courses':
        this.renderCourses(
          value
            ? Promise.resolve(JSON.parse(value))
            : fetch('https://panel.debugger.pl/api/courses').then((resp) =>
              resp.json()
            )
        );
        break;
      case 'current':
        this.current = value;
        this.renderInfo();
    }
  }
}

customElements.define('ui-footer', CourseFooter);
